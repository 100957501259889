<template>
  <div class="app-mall-goods-item" :style="{width: size}" v-if="goods" @click.stop="handleGoodsDetail">
    <div class="thumbnail bc-l" :style="{backgroundImage: backgroundImageUrl, height: size}"></div>
    <div class="padding-0-10" v-if="shopId">
      <span class="price fc-e">{{(goods.price / 100).toFixed(2)}}</span>
      <span class="fc-g">起</span>
    </div>
    <div class="padding-0-10 name">{{goods.name}}</div>
  </div>
</template>

<script>
import { getRetailMallSpuPrice } from "@/api/goods";

export default {
  props: {
    shopId: Number | String,
    goods: Object,
    size: String
  },
  computed: {
    backgroundImageUrl() {
      if (this.goods && this.goods.coverImg) {
        return `url('${this.goods.coverImg}?x-oss-process=image/resize,l_256')`;
      } else {
        return `url('/static/img/no-image.png')`;
      }
    }
  },
  methods: {
    loadGoodsRetailPrice() {
      if (this.shopId && this.goods && this.goods.id) {
        getRetailMallSpuPrice(this.shopId, this.goods.id).then(res => {
          this.goods.price = res;
        });
      }
    },
    handleGoodsDetail() {
      this.$router.push({
        path: "/mall/retail/item/" + btoa(this.goods.id)
      });
    }
  },
  mounted() {
    this.loadGoodsRetailPrice();
  }
};
</script>